import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useTranslation from "next-translate/useTranslation";
import Grid from "@material-ui/core/Grid";
import DropDownComponent from "./../DropDown";
import BrandConstants from "./../../../brands/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "",
  },
}));

export default function PriceFilter({
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  listingType,
  disabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const options =
    listingType === "sale"
      ? [...BrandConstants.PRICE_FILTER_OPTIONS_SALE]
      : [...BrandConstants.PRICE_FILTER_OPTIONS_RENT];

  const minPriceOptions = maxValue
    ? options.filter((filter) => filter.code <= maxValue)
    : options;
  const maxPriceOptions = minValue
    ? options.filter((filter) => filter.code >= minValue)
    : options;

  return (
    <Grid container spacing={1} justify="space-around">
      <Grid item xs={6} sm={6}>
        <DropDownComponent
          value={minValue}
          name="minPrice"
          label={t('min_price')}
          emptyLabel={t('min_price')}
          hideTopLabel={true}
          fullWidth
          options={minPriceOptions}
          onChange={setMinValue}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <DropDownComponent
          value={maxValue}
          name="maxPrice"
          label={t('max_price')}
          emptyLabel={t('max_price')}
          hideTopLabel={true}
          fullWidth
          options={maxPriceOptions}
          onChange={setMaxValue}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}
