import React from "react";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";
import BrandConstants from "./../../../brands/constants";
import DropDownComponent from "./../DropDown";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function PropertyTypeFilter({ value, onChange, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <DropDownComponent
      value={value}
      name="propertyType"
      label={t('property_type')}
      emptyLabel={t('property_type')}
      hideTopLabel={true}
      options={BrandConstants.PROPERTY_TYPES}
      onChange={onChange}
      fullWidth
      disabled={disabled}
    />
  );
}
