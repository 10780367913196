import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import useTranslation from "next-translate/useTranslation";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}));

export default function CustomizedSelects({
  value,
  name,
  label,
  options,
  onChange,
  hideTopLabel,
  fullWidth,
  emptyLabel,
  ...others
}) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      className={classes.root}
    >
      {!hideTopLabel ? (
        <InputLabel htmlFor="demo-customized-select-native">{t(label)}</InputLabel>
      ) : null}
      <NativeSelect
        fullWidth={fullWidth}
        id="demo-customized-select-native"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        input={<BootstrapInput />}
        {...others}
      >
        <option aria-label="None" value="">
          {!emptyLabel ? label : emptyLabel}
        </option>
        {options && options.length
          ? options.map((option) => (
              <option key={option.code} value={option.code}>
                {t(option.label)}
              </option>
            ))
          : null}
      </NativeSelect>
    </FormControl>
  );
}
