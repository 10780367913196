import React from "react";
import useTranslation from "next-translate/useTranslation";
import BrandConstants from "../../../brands/constants";
import DropDownComponent from "../DropDown";

export default function ListingType({ value, onChange, disabled }) {
  const { t } = useTranslation("common");
  return (
    <DropDownComponent
      value={value}
      name="listingType"
      label={t("listing_type")}
      emptyLabel={t("listing_type")}
      hideTopLabel={true}
      options={BrandConstants.UNIT_TYPE}
      onChange={onChange}
      fullWidth
      disabled={disabled}
    />
  );
}
