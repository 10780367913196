import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useTranslation from "next-translate/useTranslation";
import PropertyTypeFilter from "./../PropertyType";
import ListingTypeFilter from "./../ListingType";
import PriceFilter from "./../PriceFilter";
import ListingStatusDropdown from "./../ListingStatusDropdown";
import BedFilter from "./../BedFilter";
import BathroomFilter from "./../BathroomFilter";
import SearchBar from "./../SearchBar";
import CityFilter from "./../CityFilter";
import useStyles from "./style";
import { getPOI } from "./../../../store/actions/POI";
import screenSize from "./../../states/screen";
import BrandConstants from "./../../../brands/constants";
import DropDownComponent from "./../DropDown";

const FilterComponent = ({
  selectedSuggestions,
  setFilterValues,
  filterValues,
  hideListingType,
  isAdmin,
  showCity = true,
  hidePriceAndBedroom = false,
  hidePropertyType = false,
  showPropertyStatus = false,
  showCountryDistrict = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = screenSize();
  const { t } = useTranslation("common");

  const guessCity = () => {
    if (!window.Intl) {
      return "";
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let city = timezone.split("/")[1];
    return city.toLowerCase();
  };

  const city = filterValues.city;
  const [disabled, setDisabled] = useState(!!city);
  const listingType = filterValues.type;
  const propertyType = filterValues.propertyType;
  const bedroom = filterValues.bedroom;
  const bathroom = filterValues.bathroom;
  const minPrice = filterValues.minPrice;
  const maxPrice = filterValues.maxPrice;
  const autocompleteData = filterValues.autocompleteData;
  const listingStatus = filterValues.listingStatus;
  const preferredGender = filterValues.preferredGender;

  let POIData = useSelector((state) => state.POIReducer);
  const fetchSuggestions = (value) => {
    if (!value || value.length < 3) return;

    dispatch(
      getPOI({
        name: value,
        types: "TRAIN,SCHOOL,STREET",
      })
    );
  };

  const updatevalue = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!filterValues.city) {
      const city = guessCity();
      if (city) {
        updatevalue("city", city);
      }
    }
  }, []);

  useEffect(() => {
    if (disabled !== !filterValues.city) {
      setDisabled(!filterValues.city);
    }
  }, [filterValues]);

  return (
    <Grid container disableGutters={true} spacing={isDesktop ? 1 : 0} xs={12}>
      <Grid className={classes.filedWrapper} item xs={12} sm={2}>
        {!hideListingType ? (
          <ListingTypeFilter
            value={listingType}
            onChange={(value) => {
              updatevalue("type", value);
            }}
          />
        ) : (
          <CityFilter
            value={city}
            onChange={(data) => {
              updatevalue("city", data);
            }}
          />
        )}
      </Grid>

      {!isAdmin ? (
        <Grid
          className={classes.filedWrapper}
          item
          disableGutters={true}
          xs={12}
          sm={8}
        >
          <SearchBar
            selectedSuggestions={selectedSuggestions}
            suggestions={POIData.suggestions}
            isLoading={POIData.isLoading}
            setValue={(data) => {
              updatevalue("autocompleteData", data);
            }}
            value={autocompleteData}
            fetchSuggestions={fetchSuggestions}
            disabled={disabled}
          />
        </Grid>
      ) : null}
      {/* {isDesktop && !isAdmin ? (
              <Grid item disableGutters={true} xs={12} sm={2}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{ height: "100%", maxHeight: "42px" }}
                  disabled={disabled}
                >
                  {t("search")}
                </Button>
              </Grid>
            ) : null} */}
      {/* </Grid>
        </Grid> */}

      {listingType === "room-rental" ? (
        <Grid
          className={classes.filedWrapper}
          item
          xs={12}
          sm={2}
          disableGutters={true}
        >
          <DropDownComponent
            value={preferredGender}
            name="preferredGender"
            label={t("preferred_gender")}
            emptyLabel={t("preferred_gender")}
            hideTopLabel={true}
            options={BrandConstants.PREFERRED_GENDER}
            onChange={(data) => {
              updatevalue("preferredGender", data);
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
      ) : null}

      {!hidePropertyType ? (
        <Grid
          className={classes.filedWrapper}
          item
          xs={12}
          sm={2}
          disableGutters={true}
        >
          <PropertyTypeFilter
            value={propertyType}
            onChange={(data) => {
              updatevalue("propertyType", data);
            }}
            disabled={disabled}
          />
        </Grid>
      ) : null}

      {!isAdmin && !hidePriceAndBedroom ? (
        <Fragment>
          <Grid
            className={classes.filedWrapper}
            item
            xs={12}
            sm={4}
            disableGutters={true}
          >
            <PriceFilter
              minValue={minPrice}
              maxValue={maxPrice}
              listingType={listingType}
              setMinValue={(data) => {
                updatevalue("minPrice", data);
              }}
              setMaxValue={(data) => {
                updatevalue("maxPrice", data);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid
            className={classes.filedWrapper}
            item
            xs={12}
            sm={2}
            disableGutters={true}
          >
            <BedFilter
              value={bedroom}
              onChange={(data) => {
                updatevalue("bedroom", data);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <BathroomFilter
              value={bathroom}
              onChange={(data) => {
                updatevalue("bathroom", data);
              }}
              disabled={disabled}
            />
          </Grid>
        </Fragment>
      ) : null}

      {isAdmin ? (
        <Grid
          className={classes.filedWrapper}
          item
          xs={12}
          sm={2}
          disableGutters={true}
        >
          <ListingStatusDropdown
            value={listingStatus}
            onChange={(data) => {
              updatevalue("listingStatus", data);
            }}
            disabled={disabled}
          />
        </Grid>
      ) : null}
      {isDesktop ? (
        <Grid item disableGutters={true} xs={12} sm={2}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            style={{ height: "100%", maxHeight: "42px" }}
            disabled={disabled}
          >
            {t("search")}
          </Button>
        </Grid>
      ) : null}

      {showPropertyStatus ? (
        <Grid
          className={classes.filedWrapper}
          item
          xs={12}
          sm={3}
          disableGutters={true}
        >
          <DropDownComponent
            value={preferredGender}
            name="status"
            label={t("Status")}
            emptyLabel={t("Status")}
            hideTopLabel={true}
            options={BrandConstants.PROPERTY_STATUS}
            onChange={(data) => {
              updatevalue("status", data);
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
      ) : null}

    {showCountryDistrict ? (
        <Grid
          className={classes.filedWrapper}
          item
          xs={12}
          sm={3}
          disableGutters={true}
        >
          <DropDownComponent
            value={preferredGender}
            name="district"
            label={t("District")}
            emptyLabel={t("District")}
            hideTopLabel={true}
            options={BrandConstants.DISTRICT}
            onChange={(data) => {
              updatevalue("district", data);
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FilterComponent;
