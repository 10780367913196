import React from "react";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";
import DropDownComponent from "./../DropDown";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function CityFilter({ value, onChange }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.root}>
      <DropDownComponent
        value={value}
        name="city"
        label={t("city")}
        emptyLabel={t("city")}
        hideTopLabel={true}
        fullWidth
        options={[
          { code: "singapore", label: "Singapore" },
          // { code: "sydney", label: "Sydney" },
        ]}
        onChange={onChange}
      />
    </div>
  );
}
