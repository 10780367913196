import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DropDownComponent from "./../DropDown";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function ListingStatusDropdown({ value, onChange }) {
  const classes = useStyles();

  return (
    <DropDownComponent
      value={value}
      name="listingStatus"
      label="Status"
      emptyLabel="Status"
      hideTopLabel={true}
      fullWidth
      options={[
        { code: "PUBLISH", label: "PUBLISH" },
        { code: "DRAFT", label: "DRAFT" },
        { code: "EXPIRE", label: "EXPIRE" },
      ]}
      onChange={onChange}
    />
  );
}
