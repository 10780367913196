import React from "react";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";
import DropDownComponent from "./../DropDown";
import BrandConstants from "./../../../brands/constants";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function BathroomFilter({ value, onChange, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <DropDownComponent
      value={value}
      name="bathroom"
      label={t('bathroom')}
      emptyLabel={t('bathroom')}
      hideTopLabel={true}
      fullWidth
      options={BrandConstants.BATHROOM_OPTIONS}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
