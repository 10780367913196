import React from "react";
import useTranslation from "next-translate/useTranslation";
import { makeStyles } from "@material-ui/core/styles";
import DropDownComponent from "./../DropDown";
import BrandConstants from '../../../brands/constants'

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function PriceFilter({ value, onChange, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <div className={classes.root}>
      <DropDownComponent
        value={value}
        name="bedroom"
        label={t("bedroom")}
        emptyLabel={t("bedroom")}
        hideTopLabel={true}
        fullWidth
        options={BrandConstants.ROOM_LIST}
        // options={[
        //   { code: "", label: "All" },
        //   { code: "Studio", label: "Studio" },
        //   { code: 1, label: "1" },
        //   { code: 2, label: "2" },
        //   { code: 3, label: "3" },
        //   { code: 4, label: "4" },
        //   { code: 5, label: "5" },
        //   { code: 6, label: "6" },
        // ]}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
